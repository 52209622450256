.card {
  background-color: white;
  border-style: none;
  /* Add shadows to create the "card" effect */
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 12px;
  /* padding: 0.5rem; */
  margin: 3rem;
  width: 25rem;
  min-height: 8rem;
}

.cardTop {
  display: flex;
  border-style: none;
  border-radius: 12px 12px 0px 0px;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  /* background-color: white; */
}

.cardBody {
  cursor: pointer;
  border-style: none;
  border-radius: 0px 0px 12px 12px;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  /* background-color: white; */
  min-height: 8rem;
}

.card:hover {
  background-color: #eee;
}

.checkOptions {
  display: flex;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 0.25rem;
  margin-top: 0.3rem;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 0.25rem;
  margin-top: 0.3rem;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.confirmation {
  display: block;
  position: relative;
  padding-left: 1.5rem;
  padding-top: 0.2rem;
  cursor: pointer;
  font-size: 12px;
  color: #888;
  /* text-transform: capitalize; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: auto;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.confirmation input {
position: absolute;
opacity: 0;
cursor: pointer;
height: 0;
width: 0;
}

.confirmation img {
width: 100%;
height: auto;
}

/* Show the checkmark when checked */
.confirmation input:checked ~ .checkmark:after {
display: block;
}

/* Style the checkmark/indicator */
.confirmation .checkmark:after {
left: 6px;
top: 2px;
width: 5px;
height: 10px;
border: solid white;
border-width: 0 2px 2px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}

/* On mouse-over, add a grey background color */
.confirmation:hover input ~ .checkmark {
background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.confirmation input:checked ~ .checkmark {
background-color: #009688ff;
}

/* MODAL */

.modal {
  display: block;
  position: fixed;
  left: 35%;
  top: 8%;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 35rem;
  max-width: 35rem;
  max-height: 35rem;
  overflow-y: scroll;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.modal h3 {
  text-align: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.modalBody {
  padding: 1.5rem;
}

.modalBody button {
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}

.modalHeader {
  display: flex;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.materialIcon {
  color: #9ca3af;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.preview {
  margin-left: 4rem;
  margin-right: 4rem;
}