.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  overflow: auto;
  padding: 2rem;
}

.container h1 {
  padding-bottom: 2rem;
}

.container h2 {
  padding-bottom: 2rem;
}

.container ul li {
  padding-bottom: 2rem;
  font-size: 1.5rem;
}

.card {
  background-color: white;
  border-style: solid;
  border-color: #ccc;
  /* Add shadows to create the "card" effect */
  /* box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2); */
  transition: 0.3s;
  border-radius: 12px;
  padding: 0.5rem;
  margin: 3rem;
  width: 20rem;
  max-height: 18rem;
  min-height: 18rem;
  overflow-y: scroll;
  font-weight: 600;
  text-align: center;
}

.card:hover {
  background-color: #eee;
  cursor: pointer;
}

.card p {
  margin-top: 5rem;
}

.cardItem {
  cursor: pointer;
  background-color: #eee;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.cardItem:hover {
  filter: brightness(90%);
}

.cardItemOut {
  cursor: pointer;
  background-color: #ffcdd2ff;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.cardItemOut:hover {
  filter: brightness(90%);
}

.cardItemConfirmed {
  cursor: pointer;
  background-color: #cce2cb;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.cardItemConfirmed:hover {
  filter: brightness(90%);
}

.cardItemOk {
  cursor: pointer;
  background-color: #BBD6FA;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.cardItemOk:hover {
  filter: brightness(90%);
}

.img {
  padding-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}

.main {
  text-align: center;
}

.main h1 {
  text-transform: capitalize;
}
